<template>
    <!-- // TUTORIAL SOURCE: https://docs.aws.amazon.com/rekognition/latest/dg/image-bytes-javascript.html -->
    <div>
        <div v-if="show" class="p-grid">
            test
            <ul>
                <li v-for="(item, i) in items" :key="i">
                    {{ item.DetectedText }} (confidence: {{ item.Confidence }})
                </li>
            </ul>
            <Button
                v-if="isCameraOff"
                label="Camera on"
                @click="cameraOn"
            />
            <Button
                v-else
                label="Camera off"
                @click="cameraOff"
            />
            <Button
                v-if="!isCameraOff"
                label="Take image"
                @click="onCapture"
            />
            <div v-if="!isCameraOff">
                <code v-if="device">{{ device.label }}</code>
                <web-cam ref="webcam"
                         :device-id="deviceId"
                         width="100%"
                         @started="onStarted"
                         @stopped="onStopped"
                         @error="onError"
                         @cameras="onCameras"
                         @camera-change="onCameraChange"
                />
                <select v-model="camera">
                    <option>-- Select Device --</option>
                    <option v-for="device in devices"
                            :key="device.deviceId"
                            :value="device.deviceId"
                    >
                        {{ device.label }}
                    </option>
                </select>
            </div>

            <div>
                <img :src="image" style="max-width:600px;" class="p-col-12">
                {{ image }}
            </div>
        </div>
        <input id="fileToUpload" type="file" name="fileToUpload" accept="image/*" @change="ProcessImageLoaded">
        <div v-if="$store.state.debug.log">
            <br>
            <!-- ITEMS ************************** -->
            <vue-json-pretty :data="image" />
        </div>
    </div>
</template>

<script>
const AWS = require('aws-sdk')
import { WebCam } from "vue-web-cam";
import { find, head, last } from "lodash";

export default {
        components: {
                WebCam
        },
        data() {
                return {
                        autoTakeImage: true,
                        items: [],
                        image: null,
                        imageBase64: null,
                        isCameraOff: true,
                        checkImage: null,
                        camera: null,
                        deviceId: null,
                        devices: [],
                        countCheckImage: 0,
                        maxCountCheckImage: 15
                }
        },
        computed: {
                show() {
                        return true;
                },
                device() {
                        return find(this.devices, n => n.deviceId == this.deviceId);
                },
        },
        watch: {
                countCheckImage(newValue, oldValue) {
                        if (newValue > this.maxCountCheckImage) {
                                this.cameraOff()
                        }
                },
                camera: function(id) {
                        this.deviceId = id;
                },
                devices: function() {
                        // Once we have a list select the first one
                        let tmpDevice = last(this.devices);
                        if (tmpDevice) {
                                this.camera = tmpDevice.deviceId;
                                this.deviceId = tmpDevice.deviceId;
                        }
                }
        },
        created() {
        },
        mounted() {
        },
        beforeDestroy () {
                clearInterval(this.checkImage)
        },
        methods: {
                checkImageInput() {
                        let self = this
                        if (this.autoTakeImage) {
                                this.checkImage = setInterval(() => {
                                        this.countCheckImage++
                                        console.log('check')
                                        self.onCapture()
                                }, 5000)
                        }
                },
                cameraOn() {
                        this.countCheckImage = 0
                        this.checkImageInput()
                        this.isCameraOff = false
                },
                cameraOff() {
                        clearInterval(this.checkImage)
                        this.isCameraOff = true
                },
                //Calls DetectText API and shows estimated ages of detected text
                DetectText(imageData) {
                        console.log('detect text')
                        AWS.region = "eu-central-1";
                        var rekognition = new AWS.Rekognition();
                        var params = {
                                Image: {
                                        Bytes: imageData
                                },
                        };
                        let self = this
                        rekognition.detectText(params, function (err, data) {
                                if (err) console.log(err, err.stack); // an error occurred
                                else {
                                        console.log(data)
                                        self.items.splice(0, self.items.length)
                                        self.items.push(...data.TextDetections)
                                        console.log('ITEMS *****************************')
                                        console.log(self.items)
                                }
                        });
                },
                //Process captured image and unencodes image bytes for Rekognition DetectText API
                ProcessImageCaptured() {
                        console.log('process captured image')
                        // console.log(this.image)
                        // if (!this.image || !this.image.length < 100) return
                        // console.log('process captured image')

                        this.AnonLog();

                        // Load base64 encoded image
                        var image = null;
                        // img.src = e.target.result;
                        // self.image = img.src
                        var jpg = true;
                        try {
                                image = atob(this.image.split("data:image/jpeg;base64,")[1]);

                        } catch (e) {
                                jpg = false;
                        }
                        if (jpg == false) {
                                try {
                                        image = atob(this.image.split("data:image/png;base64,")[1]);
                                } catch (e) {
                                        console.log("Not an image file Rekognition can process");
                                        return;
                                }
                        }
                        //unencode image bytes for Rekognition DetectText API
                        var length = image.length;
                        let imageBytes = new ArrayBuffer(length);
                        var ua = new Uint8Array(imageBytes);
                        for (var i = 0; i < length; i++) {
                                ua[i] = image.charCodeAt(i);
                        }
                        //Call Rekognition
                        this.DetectText(imageBytes);
                },
                //Loads selected image and unencodes image bytes for Rekognition DetectText API
                ProcessImageLoaded() {
                        console.log('process image')
                        this.AnonLog();
                        var control = document.getElementById("fileToUpload");
                        var file = control.files[0];

                        // Load base64 encoded image
                        var reader = new FileReader();
                        let self = this
                        reader.onload = (function (theFile) {
                                return function (e) {
                                        var img = document.createElement('img');
                                        var image = null;
                                        img.src = e.target.result;
                                        self.image = img.src
                                        var jpg = true;
                                        try {
                                                image = atob(e.target.result.split("data:image/jpeg;base64,")[1]);

                                        } catch (e) {
                                                jpg = false;
                                        }
                                        if (jpg == false) {
                                                try {
                                                        image = atob(e.target.result.split("data:image/png;base64,")[1]);
                                                } catch (e) {
                                                        alert("Not an image file Rekognition can process");
                                                        return;
                                                }
                                        }
                                        //unencode image bytes for Rekognition DetectText API
                                        var length = image.length;
                                        let imageBytes = new ArrayBuffer(length);
                                        var ua = new Uint8Array(imageBytes);
                                        for (var i = 0; i < length; i++) {
                                                ua[i] = image.charCodeAt(i);
                                        }
                                        //Call Rekognition
                                        self.DetectText(imageBytes);
                                };
                        })(file);
                        reader.readAsDataURL(file);
                },
                //Provides anonymous log on to AWS services
                AnonLog() {
                        // Configure the credentials provider to use your identity pool
                        AWS.config.region = 'eu-central-1'; // Region
                        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                                IdentityPoolId: 'eu-central-1:aedab169-3918-4846-9a52-b19a6aceca3b',
                        });
                        // Make the call to obtain credentials
                        AWS.config.credentials.get(function () {
                                // Credentials will be available when this function is called.
                                var accessKeyId = AWS.config.credentials.accessKeyId;
                                var secretAccessKey = AWS.config.credentials.secretAccessKey;
                                var sessionToken = AWS.config.credentials.sessionToken;
                        });
                },
                onCapture() {
                        this.image = this.$refs.webcam.capture();
                        // this.isCameraOff = true
                        console.log('onCapture')
                        console.log(this.image)
                        this.ProcessImageCaptured()
                },
                onStarted(stream) {
                        console.log("On Started Event", stream);
                },
                onStopped(stream) {
                        console.log("On Stopped Event", stream);
                },
                onStop() {
                        this.$refs.webcam.stop();
                },
                onStart() {
                        this.$refs.webcam.start();
                },
                onError(error) {
                        console.log("On Error Event", error);
                },
                onCameras(cameras) {
                        this.devices = cameras;
                        console.log("On Cameras Event", cameras);
                },
                onCameraChange(deviceId) {
                        this.deviceId = deviceId;
                        this.camera = deviceId;
                        console.log("On Camera Change Event", deviceId);
                },
        },
};
</script>

<style scoped  lang="scss">
.canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.canvas-wrapper {
  position: relative;
  z-index: 1;
}</style>
